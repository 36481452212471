<template>
  <div>
    <div class="mt-16 grid justify-center">
      <app-cube :size="44" :stroke-width="3" />
    </div>
    <slot />
    <div class="mb-16 mt-4 flex justify-center gap-1">
      <u-button
        variant="link"
        size="xs"
        to="/docs/privacy-policy"
        target="_blank"
      >
        Privacy Policy
      </u-button>
      &middot;
      <u-button
        variant="link"
        size="xs"
        to="/docs/terms-of-service"
        target="_blank"
      >
        Terms of Service
      </u-button>
    </div>
  </div>
</template>
